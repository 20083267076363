export const data = function () {
  return {
    page: {
      title: 'Relatório de Pedido',
    },
    breadcrumbs: [
      {
        text: 'Relatórios',
        disabled: false,
      },
      {
        text: 'Relatório de Pedido',
        disabled: false,
      },
    ],
  }
}
