var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersDia,"items":_vm.data,"items-per-page":10000,"single-expand":true,"expanded":_vm.expandedDia,"hide-default-footer":true,"item-key":"idDia","show-expand":""},on:{"update:expanded":function($event){_vm.expandedDia=$event}},scopedSlots:_vm._u([{key:"item.dia",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dia)+" ")]}},{key:"item.quantidade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mask_peso(item.quantidade))+" ")]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"mt-10"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerstotalDia,"items":[{ descricao: 'Total', quantidade: _vm.totalQuantidadeDia }],"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.quantidade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mask_peso(item.quantidade))+" ")]}}],null,true)})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"relatorio",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"headers":_vm.headersProduto,"items":item.produtos,"hide-default-footer":true,"item-key":"idProduto","show-expand":""},scopedSlots:_vm._u([{key:"item.descricao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.descricao)+" ")]}},{key:"item.quantidade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mask_peso(item.quantidade))+" ")]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"mt-10"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerstotalProduto,"items":[{ descricao: 'Total', quantidade: _vm.totalQuantidadeProduto(item) }],"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.quantidade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mask_peso(item.quantidade))+" ")]}}],null,true)})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"relatorio",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"headers":_vm.headersEmbalagem,"items":item.EMBALAGENS,"hide-default-footer":true,"item-key":"idEmbalagem"},scopedSlots:_vm._u([{key:"item.descricao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.descricao)+" ")]}},{key:"item.quantidade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mask_peso(item.quantidade))+" ")]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"mt-10"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerstotalEmbalagem,"items":[{ descricao: 'Total', quantidade: _vm.totalQuantidadeEmbalagem(item) }],"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.quantidade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mask_peso(item.quantidade))+" ")]}}],null,true)})],1)]},proxy:true}],null,true)})],1)]}}],null,true)})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }