<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col>
        <relatorio-pedido-list-filtro @search="handleGetPedidos" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <relatorio-pedido-component ref="pedidos" v-bind:params="params" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import RelatorioPedidoListFiltro from '@/components/patio/relatorioPedido/RelatorioPedidoListFiltro.vue'
import RelatorioPedidoComponent from '@/components/patio/relatorioPedido/Pedido.vue'

import { data } from './_data'

export default {
  name: 'RelatorioPedido',
  components: {
    RelatorioPedidoListFiltro,
    RelatorioPedidoComponent,
  },
  data,
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('relatorioPedidos', ['params']),
  },
  methods: {
    async handleGetPedidos() {
      await this.$refs.pedidos.atualizaDados(this.params)
    },
  },
}
</script>
