import { mapState } from 'vuex'

import { LOCAL_STORAGE_ID } from "@/utils/relatorioPedido"

export const computed = {
  ...mapState('auth', ['empresaAtual']),
  ...mapState('fornecedores', ['fornecedores']),

  showData() {
    return this.params.data_inicio?.visible || this.params?.data_fim.visible
  },
  showFornecedor() {
    return this.params.fornecedor_cnpj?.visible || this.params.produto_desc?.visible || this.params.sub_produto_desc?.visible
  },
  areSettingsEmpty() {
    console.log(JSON.parse(localStorage.getItem(LOCAL_STORAGE_ID)))
    return !(this.showData || this.showFornecedor || this.showContratos || this.showRegiao || this.showVeiculo)
  },
  selectedOptions() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_ID)) || []
  }
}

